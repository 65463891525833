import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TextField, List, DateField, Show, SimpleShowLayout, Create, SimpleForm, required, 
    NumberField, ReferenceField, DateTimeInput, TextInput, NumberInput, Edit } from "react-admin";
import { idFilter } from "../util/filters";

export const PricingList = props => (
    <List filters={idFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <DateField source="submitted" showTime={true}/>
            <DateField source="valid_from" showTime={true}/>
            <DateField source="valid_to" showTime={true}/>
            <ReferenceField source="admin_id" reference="users">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField source="customer_id" reference="businessentities">
                <TextField source="id" />
            </ReferenceField>
            <NumberField source="energy_price" />
        </CustomizableDatagrid>
    </List>
);

export const PricingShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <DateField source="submitted" showTime={true}/>
            <DateField source="valid_from" showTime={true}/>
            <DateField source="valid_to" showTime={true}/>
            <ReferenceField source="admin_id" reference="users">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField source="customer_id" reference="businessentities">
                <TextField source="id" />
            </ReferenceField>
            <NumberField source="energy_price" />
        </SimpleShowLayout>
    </Show>
);

export const PricingCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <DateTimeInput source="valid_from" showTime={true} validate={required()}/>
            <DateTimeInput source="valid_to" showTime={true}/>
            <TextInput source="customer_id"/>
            <NumberInput source="energy_price" validate={required()}/>
        </SimpleForm>
    </Create>
);

export const PricingEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <DateTimeInput source="valid_from" showTime={true} validate={required()}/>
            <DateTimeInput source="valid_to" showTime={true}/>
            <TextInput source="customer_id"/>
            <NumberInput source="energy_price" validate={required()}/>
        </SimpleForm>
    </Edit>
);