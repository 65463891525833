// in src/App.js
import * as React from "react";
import { Admin, Resource } from 'react-admin';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import { UserList, UserEdit, UserCreate, UserShow } from "./datamodels/users";
import { BEList, BEShow, BEEdit, BECreate } from "./datamodels/businessentities";
import { DerSystemList, DerSystemShow, DerSystemEdit, DerSystemCreate } from "./datamodels/dersystems";
import { MFAList, MFAShow, MFAEdit, MFACreate } from "./datamodels/mfas";
import { BookingList, BookingShow } from "./datamodels/bookings";
import { PricingList, PricingShow, PricingCreate, PricingEdit } from "./datamodels/pricings";
import { ScheduleList, ScheduleShow, ScheduleEdit, ScheduleCreate } from "./datamodels/schedules";
import { ReportList, ReportShow, ReportEdit, ReportCreate } from "./datamodels/reports";
import { RemOptList, RemOptShow, RemOptCreate } from "./datamodels/remopts";
import { ScheduleTemplateList, ScheduleTemplateShow, ScheduleTemplateCreate, ScheduleTemplateEdit } from "./datamodels/scheduleTemplates"
import { ExecutionTaskList, ExecutionTaskShow, ExecutionTaskeCreate, ExecutionTaskEdit } from "./datamodels/executionTasks";
import { SettlementList, SettlementShow } from "./datamodels/settlements";
import { DatenspendeList, DatenspendeShow, DatenspendeCreate, DatenspendeEdit } from "./datamodels/datenspende";

const App = () => (
    <Admin authProvider={authProvider} dataProvider={dataProvider}>
        <Resource name="users" list={UserList} show={UserShow} create={UserCreate} edit={UserEdit}/>
        <Resource name="businessentities" list={BEList} show={BEShow} create={BECreate} edit={BEEdit} />
        <Resource name="dersystems" list={DerSystemList} show={DerSystemShow} create={DerSystemCreate} edit={DerSystemEdit}/>
        <Resource name="mfas" list={MFAList} show={MFAShow} edit={MFAEdit} create={MFACreate}/>
        <Resource name="bookings" list={BookingList} show={BookingShow} />
        <Resource name="pricings" list={PricingList} show={PricingShow} edit={PricingEdit} create={PricingCreate} />
        <Resource name="controls" list={ScheduleList} show={ScheduleShow} edit={ScheduleEdit} create={ScheduleCreate} />
        <Resource name="reports" list={ReportList} show={ReportShow} edit={ReportEdit} create={ReportCreate} />
        <Resource name="rem" list={RemOptList} show={RemOptShow} create={RemOptCreate}/>
        <Resource name="scheduletemplates" list={ScheduleTemplateList} show={ScheduleTemplateShow} edit={ScheduleTemplateEdit} create={ScheduleTemplateCreate}/>
        <Resource name="executiontasks" list={ExecutionTaskList} show={ExecutionTaskShow} edit={ExecutionTaskEdit} create={ExecutionTaskeCreate} />
        <Resource name="settlements" list={SettlementList} show={SettlementShow}/>
        <Resource name="datenspende" list={DatenspendeList} show={DatenspendeShow} create={DatenspendeCreate} edit={DatenspendeEdit}/>
    </Admin>
);

export default App;
