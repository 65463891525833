import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TextField, List, DateField, ArrayField, ChipField, Show, TabbedShowLayout, NumberField, BooleanField, Datagrid, ReferenceField, NumberInput, BooleanInput,
    TextInput, required, Edit, SimpleForm, ArrayInput, SimpleFormIterator, Create, DateTimeInput, Tab, SelectInput } from "react-admin";
import { idFilter } from "../util/filters";

const typeChoices = [
    {id: "CONSUMER", name: "CONSUMER"},
    {id: "PRODUCER", name: "PRODUCER"},
];

export const MFAList = props => (
    <List filters={idFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <DateField source="submitted" showTime={true}/>
            <DateField source="valid_from" showTime={true}/>
            <DateField source="valid_to" showTime={true}/>
            <DateField source="flex_start" showTime={true}/>
            <DateField source="flex_end" showTime={true}/>
            <NumberField source="energy_kwh" />
            <NumberField source="min_amp_kw" />
            <NumberField source="max_amp_kw" />
            <NumberField source="reliability" />
            <ChipField source="pricing._cls" />
            <ChipField source="state" />
            <ReferenceField source="dersystem_id" reference="dersystems">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField source="provider_id" reference="businessentities">
                <TextField source="id" />
            </ReferenceField>
        </CustomizableDatagrid>
    </List>
);

export const MFAShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Base">
                <TextField source="id" />
                <DateField source="modification_date" showTime={true}/>
                <DateField source="submitted" showTime={true}/>
                <ReferenceField source="dersystem_id" reference="dersystems">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField source="provider_id" reference="businessentities">
                    <TextField source="id" />
                </ReferenceField>
                <TextField source="type" />
                <BooleanField source="predictability" />
                <BooleanField source="interruptable" />
                <NumberField source="shiftability" />
                <NumberField source="reliability" />
                <TextField source="type" />
            </Tab>
            <Tab label="Timeframe">
                <DateField source="valid_from" showTime={true}/>
                <DateField source="valid_to" showTime={true}/>
                <DateField source="flex_start" showTime={true}/>
                <DateField source="flex_end" showTime={true}/>
            </Tab>
            <Tab label="Energy/Power">
                <NumberField source="energy_kwh" />
                <NumberField source="min_amp_kw" />
                <NumberField source="max_amp_kw" />
            </Tab>
            <Tab label="Pricing">
                <ChipField source="pricing._cls" />
                <NumberField source="pricing.price_kwh" />  
                <NumberField source="pricing.prices_kwh" />
                <ChipField source="pricing.search_telemetry.price_model" />   
                <NumberField source="pricing.search_telemetry.max_price_kwh" />          
                <NumberField source="price" />
            </Tab>
            <Tab label="State">
                <TextField source="state" />
                <ArrayField source="state_history">
                    <Datagrid>
                        <ChipField source="state" />
                        <DateField source="timestamp" showTime={true} />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const MFAEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <DateTimeInput source="valid_from" validate={required()} showTime={true}/>
            <DateTimeInput source="valid_to" validate={required()} showTime={true}/>
            <DateTimeInput source="flex_start" validate={required()} showTime={true}/>
            <DateTimeInput source="flex_end" validate={required()} showTime={true}/>
            <SelectInput source="type" choices={typeChoices} validate={required()} />
            <NumberInput source="energy_kwh" validate={required()}/>
            <NumberInput source="min_amp_kw" validate={required()}/>
            <NumberInput source="max_amp_kw" validate={required()}/>
            <TextInput source="dersystem_id" validate={required()}/>
            <TextInput source="provider_id" validate={required()}/>
            <BooleanInput source="predictability"/>
            <BooleanInput source="interruptable"/>
            <NumberInput source="pricing.price_kwh" />
            <ArrayInput source="pricing.prices_kwh">
                <SimpleFormIterator>
                    <NumberInput />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const MFACreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <DateTimeInput source="valid_from" showTime={true} validate={required()}/>
            <DateTimeInput source="valid_to" showTime={true} validate={required()}/>
            <DateTimeInput source="flex_start" showTime={true} validate={required()}/>
            <DateTimeInput source="flex_end" showTime={true} validate={required()}/>
            <SelectInput source="type" choices={typeChoices} validate={required()} />
            <NumberInput source="energy_kwh" validate={required()}/>
            <NumberInput source="min_amp_kw" validate={required()}/>
            <NumberInput source="max_amp_kw" validate={required()}/>
            <TextInput source="dersystem_id" validate={required()}/>
            <TextInput source="provider_id" validate={required()}/>
            <BooleanInput source="predictability"/>
            <BooleanInput source="interruptable"/>
            <NumberInput source="pricing.price_kwh" />
            <ArrayInput source="pricing.prices_kwh">
                <SimpleFormIterator>
                    <NumberInput />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);
