import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TextField, List, DateField, ArrayField, ChipField, Show, SimpleShowLayout, NumberField, BooleanField, Datagrid, ReferenceField, NumberInput, BooleanInput,
    TextInput, required, Edit, SimpleForm, ArrayInput, SimpleFormIterator, Create } from "react-admin";
import { idFilter } from "../util/filters";

export const DerSystemList = props => (
    <List filters={idFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true} />
            <TextField source="internal_id" />
            <TextField source="owner_name" />
            <TextField source="owner_id" />
            <TextField source="zone_id" />
            <NumberField source="reliability" />
            <ReferenceField source="responsible_party_id" reference="businessentities">
                <TextField source="id" />
            </ReferenceField>
        </CustomizableDatagrid>
    </List>
);

export const DerSystemShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <TextField source="internal_id" />
            <TextField source="owner_name" />
            <TextField source="owner_id" />
            <TextField source="zone_id" />
            <NumberField source="reliability" />
            <ReferenceField source="responsible_party_id" reference="businessentities">
                <TextField source="id" />
            </ReferenceField>
            <NumberField source="gps.latitude" />
            <NumberField source="gps.longitude" />
            <TextField source="address.city" />
            <TextField source="address.street" />
            <TextField source="address.zip" />
            <TextField source="address.country" />
            <TextField source="sys_type" />
            <TextField source="tech_type" />
            <BooleanField source="forecast_capability" />
            <BooleanField source="schedule_capability" />
            <ArrayField source="com_params">
                <Datagrid>
                    <ChipField source="_cls" />
                    <TextField source="jabber_id" />
                    <TextField source="logical_device_name" />
                    <TextField source="server" />
                    <TextField source="port" />
                    <TextField source="topic" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
)

export const DerSystemEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="internal_id" validate={required()} />
            <TextInput source="owner_name" validate={required()} />
            <TextInput source="owner_id" validate={required()} />
            <TextInput source="zone_id" validate={required()} />
            <NumberInput source="reliability" validate={required()} />
            <TextInput source="responsible_party_id" validate={required()} />
            <NumberInput source="reliability" validate={required()} />
            <NumberInput source="gps.latitude" validate={required()} />
            <NumberInput source="gps.longitude" validate={required()} />
            <TextInput source="address.city"/>
            <TextInput source="address.street"/>
            <TextInput source="address.zip"/>
            <TextInput source="address.country"/>
            <TextInput source="sys_type"/>
            <TextInput source="tech_type"/>
            <BooleanInput source="forecast_capability"/>
            <BooleanInput source="schedule_capability"/>
            <ArrayInput source="com_params">
                <SimpleFormIterator>
                    <TextInput source="jabber_id" />
                    <TextInput source="logical_device_name" />
                    <TextInput source="server" />
                    <TextInput source="port" />
                    <TextInput source="topic" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const DerSystemCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="internal_id" validate={required()} />
            <TextInput source="owner_name" validate={required()} />
            <TextInput source="owner_id" validate={required()} />
            <TextInput source="zone_id" validate={required()} />
            <NumberInput source="reliability" validate={required()} />
            <TextInput source="responsible_party_id" validate={required()} />
            <NumberInput source="reliability" validate={required()} />
            <NumberInput source="gps.latitude" validate={required()} />
            <NumberInput source="gps.longitude" validate={required()} />
            <TextInput source="address.city"/>
            <TextInput source="address.street"/>
            <TextInput source="address.zip"/>
            <TextInput source="address.country"/>
            <TextInput source="sys_type"/>
            <TextInput source="tech_type"/>
            <BooleanInput source="forecast_capability"/>
            <BooleanInput source="schedule_capability"/>
            <ArrayInput source="com_params">
                <SimpleFormIterator>
                    <TextInput source="jabber_id" />
                    <TextInput source="logical_device_name" />
                    <TextInput source="server" />
                    <TextInput source="port" />
                    <TextInput source="topic" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);