import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TextField, List, DateField, EmailField, ArrayField, SingleFieldList, ChipField, Show, SimpleShowLayout, Datagrid, Edit, SimpleForm, TextInput,
SelectArrayInput, SelectInput, required, ArrayInput, SimpleFormIterator, Create } from "react-admin";
import { idNameFilter } from "../util/filters";

const stateChoices = [{id: "ACTIVE", name: "ACTIVE"}];
const roleChoices = [
    {id: 'FlexProvider', name: "FlexProvider"},
    {id: 'FlexConsumer', name: "FlexConsumer"},
    {id: 'Administrator', name: "Administrator"},
    {id: 'VNBKAPA', name: 'VNBKAPA'},
]

export const BEList = props => (
    <List filters={idNameFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="phone" />
            <TextField source="business_roles" />
            <TextField source="status" />
            <ArrayField source="balancegroups">
                <SingleFieldList>
                    <ChipField source="balancegroup_id" />
                </SingleFieldList>
            </ArrayField>            
        </CustomizableDatagrid>
    </List>
);

export const BEShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="phone" />
            <TextField source="business_roles" />
            <TextField source="status" />
            <ArrayField source="balancegroups">
                <Datagrid>
                    <ChipField source="balancegroup_id" />
                    <TextField source="dso" />
                    <TextField source="tso" />
                    <TextField source="trader_ids" />
                </Datagrid>
            </ArrayField>
            <TextField source="address.city" />
            <TextField source="address.street" />
            <TextField source="address.zip" />
            <TextField source="address.country" />
            <TextField source="com_prefs.mqtt_handle" />
        </SimpleShowLayout>
    </Show>
);

export const BEEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="name" validate={required()} />
            <TextInput source="email" type="email" validate={required()} />
            <TextInput source="phone" />
            <SelectArrayInput source="business_roles" choices={roleChoices} validate={required()} />
            <SelectInput source="status" choices={stateChoices} validate={required()} />
            <ArrayInput source="balancegroups">
                <SimpleFormIterator>
                    <TextInput source="balancegroup_id"/>
                    <TextInput source="dso"/>
                    <TextInput source="tso"/>
                    <ArrayInput source="trader_ids">
                        <SimpleFormIterator>
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="address.city"/>
            <TextInput source="address.street"/>
            <TextInput source="address.zip"/>
            <TextInput source="address.country"/>
            <TextInput source="com_prefs.mqtt_handle"/>
        </SimpleForm>
    </Edit>
);

export const BECreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <TextInput source="email" type="email" validate={required()} />
            <TextInput source="phone" />
            <SelectArrayInput source="business_roles" choices={roleChoices} validate={required()} />
            <SelectInput source="status" choices={stateChoices} validate={required()} />
            <ArrayInput source="balancegroups">
                <SimpleFormIterator>
                    <TextInput source="balancegroup_id"/>
                    <TextInput source="dso"/>
                    <TextInput source="tso"/>
                    <ArrayInput source="trader_ids">
                        <SimpleFormIterator>
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="address.city"/>
            <TextInput source="address.street"/>
            <TextInput source="address.zip"/>
            <TextInput source="address.country"/>
            <TextInput source="com_prefs.mqtt_handle"/>
        </SimpleForm>
    </Create>
);