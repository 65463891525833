import { fetchUtils } from 'react-admin';

const apiUrl = window.BACKEND_URL;
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

// pages to store the continuation tokens to directly access a "page"
let pages = [];

/**
 * Build a backend compatible sort string for the given field and order.
 * @param {*} field 
 * @param {*} order 
 * @returns 
 */
function buildSortString(field, order) {
    let prefix;

    if (order === 'DESC') {
        prefix = '-'; 
    } else {
        prefix = '%2B'; 
    }

    return prefix + field;
}

export default {
    // GET a list of resources
    getList: (resource, params) => {
        // get the parameters from the page      
        const sort = params.sort;
        const filter = params.filter;
        const { page, perPage } = params.pagination;

        // build the pagination string
        const paginationString = `pagesList=True&pageSize=${perPage}`;
        let url = `${apiUrl}/${resource}/?${paginationString}`;
        // and a continuation token if you want to access a further page
        if (page > 1) {
            const pageIndex = page - 2;
            let token = pages[pageIndex];
            token = token.replace('+', '%2B');
            url += '&continuationToken=' + token;
        }

        // build the sort string if the results should be sorted by a field
        if (sort) {
            const sortString = `&sortBy=${buildSortString(sort.field, sort.order)}`;
            url += sortString;
        }

        // build the backend compatible filter string
        // be aware the backend just supports filtering by one field!
        if (filter && Object.keys(filter).length > 0) {
            const propNames = Object.keys(filter);
            // iterate over the list of properties to filter and add a filter each
            for (const property of propNames) {
                const value = filter[property];
                const filterString = `&filter[${property}]=${value}`;
                url += filterString;
            }
        }

        // access the backend
        return httpClient(url).then(function({ headers, json }) {
            if (json) {
                pages = json.pagination.pages;
                return {
                    data: json.data,
                    total: parseInt(json.pagination.total),
                }
            } else {
                return {
                    data: [],
                    total: 0,
                }
            }
        });
    },

    // GET a specific resource
    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json.data,
        })),

    getMany: (resource, params) => {
        // base url, limited to 100 results
        let url = `${apiUrl}/${resource}/?pageSize=100`;

        // add all the ids from the parameters as filters
        let idString = "[";
        for (const id of params.ids) {
            idString += `${id},`;
        }
        // remove the last ,
        idString = idString.slice(0, -1);
        idString += "]";

        url += `&filter[id__in]=${idString}`;

        // access the backend
        return httpClient(url).then(function({ headers, json }) {
            return {
                data: json.data,
            }
        });
    },
    
    // PUT a specific resource
    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json.data })),

    // POST a new resource    
    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.data.id },
        })),
    
    // DELETE a resource
    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json.data })),
};
