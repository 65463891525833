import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { List, TextField, DateField, ReferenceField, Show, TabbedShowLayout,NumberField, Tab, ArrayField, Datagrid, ChipField, FileField } from 'react-admin';
import { idFilter } from "../util/filters";

export const SettlementList = props => (
    <List filters={idFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <ReferenceField source="businessentity_id" reference="businessentities">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="businessentity_name" />
            <DateField source="date_from" showTime={true}/>
            <DateField source="date_to" showTime={true}/>
            <DateField source="invoice_date" showTime={true}/>
        </CustomizableDatagrid>
    </List>
);

export const SettlementShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Base">
                <TextField source="id" />
                <DateField source="modification_date" showTime={true}/>
                <ReferenceField source="businessentity_id" reference="businessentities">
                    <TextField source="id" />
                </ReferenceField>
                <TextField source="businessentity_name" />
                <DateField source="date_from" showTime={true}/>
                <DateField source="date_to" showTime={true}/>
                <DateField source="invoice_date" showTime={true}/>
                <NumberField source="vat_rate" />
            </Tab>
            <Tab label="Address">
                <TextField source="recipient_address.city" />
                <TextField source="recipient_address.street" />
                <TextField source="recipient_address.zip" />
                <TextField source="recipient_address.country" />
            </Tab>
            <Tab label="Cleared Bookings">
                <ArrayField source="cleared_bookings">
                    <Datagrid>
                        <ChipField source="_cls" />
                        <ReferenceField source="booking_id" reference="bookings">
                            <TextField source="id" />
                        </ReferenceField>
                        <NumberField source="sum_mfa_price" />
                        <NumberField source="fee_kwh" />
                        <NumberField source="fee" />
                        <NumberField source="overall_costs" />
                        <ArrayField source="cleared_mfas">
                            <Datagrid>
                                <ReferenceField source="mfa_id" reference="mfas">
                                    <TextField source="id" />
                                </ReferenceField>
                                <TextField source="status_before_clearing" />
                                <TextField source="price_model" />
                                <NumberField source="energy_kwh" />
                                <NumberField source="avg_price_kwh" />
                                <NumberField source="price" />
                            </Datagrid>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="PDF">
                <FileField source="pdf" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
