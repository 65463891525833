/**
 * Extremly simple error handling, just throw the error with the corresponding message if one is set
 * otherwise throw it with a simple default message.
 * 
 * @param {*} error 
 */
function throwErrorMessage(error) {
    let msg = 'Network error';
    if (error.message) {
        msg = error.message;
    }
    throw new Error(msg);
}

const backendUrl = window.BACKEND_URL;

const authProvider = {
    // Login at the backend and store the JWT in the local storage
    // Error handling not yet implemented
    login: ({ username, password }) =>  {
        const request = new Request(`${backendUrl}sessions/login`, {
            method: 'POST',
            body: JSON.stringify({ "email": username, "password": password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.status);
                }
                return response.text();
            })
            .then(( token ) => {
                localStorage.setItem('token', token);
            })
            .catch(( error ) => {
                throwErrorMessage(error);
            });
    },
    // Logout by devalidating the JWT at the backend and removing it from local storage
    // Error handling not yet implemented
    logout: () => {
        if (localStorage.getItem('token')) {
            const request = new Request(`${backendUrl}sessions/logout`, {
                method: 'PUT',
                headers: new Headers({
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                })
            });
            return fetch(request)
                .then(response => {
                    if (response.status !== 202) {
                        throw new Error(response);
                    }
                    return response.json();
                })
                .then(( json ) => {
                    localStorage.removeItem('token');
                })
                .catch(( error ) => {
                    localStorage.removeItem('token');
                    throwErrorMessage(error);
                });
        } else {
            return Promise.resolve()
        }
    },
    // Check if the session is still valid (at the backend)
    // Error handling not yet implemented
    checkAuth: () => {
        if (localStorage.getItem('token')) {
            const request = new Request(`${backendUrl}sessions/isValid`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                })
            });
            return fetch(request)
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error(response);
                    }
                    return response.json();
                })
                .then(( json ) => {
                    if (json.data.valid) {
                        return Promise.resolve();
                    }
                    return Promise.reject();
                })
                .catch(( error ) => {
                    throwErrorMessage(error);
                });
        } else {
            return Promise.reject();
        }
    },
    // Permission management not yet implemented
    getPermissions: () => {
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        // logout on error codes 401 and 403
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
};

export default authProvider;
