import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { List, TextField, EmailField, DateField, ReferenceField, TextInput, required, Edit, SimpleForm, Create, Show, SimpleShowLayout, PasswordInput,
    SelectInput, SelectArrayInput } from 'react-admin';
import { idNameFilter } from "../util/filters";

const stateChoices = [{id: "ACTIVE", name: "ACTIVE"}];
const roleChoices = [
    {id: 'FlexProvider', name: "FlexProvider"},
    {id: 'FlexConsumer', name: "FlexConsumer"},
    {id: 'Administrator', name: "Administrator"},
    {id: 'Datenspender', name: "Datenspender"},
]

export const UserList = props => (
    <List filters={idNameFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <TextField source="name" />
            <EmailField source="email" />
            <ReferenceField source="businessentity_id" reference="businessentities">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="roles"/>
            <TextField source="status" />
        </CustomizableDatagrid>
    </List>
);

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <TextField source="name" />
            <EmailField source="email" />
            <ReferenceField source="businessentity_id" reference="businessentities">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="roles"/>
            <TextField source="status" />
            <TextField source="password_hash" />
            <TextField source="password_salt" />
        </SimpleShowLayout>
    </Show>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="businessentity_id" validate={required()} />
            <TextInput source="name" validate={required()} />
            <TextInput source="email" type="email" validate={required()} />
            <PasswordInput source="password" />
            <SelectArrayInput source="roles" choices={roleChoices} validate={required()} />
            <SelectInput  source="status" choices={stateChoices} validate={required()} />
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="businessentity_id" validate={required()} />
            <TextInput source="name" validate={required()} />
            <TextInput source="email" type="email" validate={required()} />
            <PasswordInput source="password" />
            <SelectArrayInput source="roles" choices={roleChoices} validate={required()} />
            <SelectInput source="status" choices={stateChoices} validate={required()} />
        </SimpleForm>
    </Edit>
);