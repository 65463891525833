import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TextField, List, DateField, ArrayField, ChipField, Show, NumberField, Datagrid, ReferenceField, SimpleShowLayout, NumberInput,
   TextInput, required, Edit, SimpleForm, ArrayInput, SimpleFormIterator, Create, DateTimeInput } from "react-admin";
import { idFilter } from "../util/filters";

export const ScheduleList = props => (
    <List filters={idFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <DateField source="date" showTime={true}/>
            <ReferenceField source="booking_id" reference="bookings">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField source="mfa_id" reference="mfas">
                <TextField source="id" />
            </ReferenceField>
            <ChipField source="validation_status"/>
        </CustomizableDatagrid>
    </List>
);

export const ScheduleShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <DateField source="date" showTime={true}/>
            <ReferenceField source="booking_id" reference="bookings">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField source="mfa_id" reference="mfas">
                <TextField source="id" />
            </ReferenceField>
            <ChipField source="validation_status"/>
            <ArrayField source="ptus">
                <Datagrid>
                    <DateField source="date" showTime={true}/>
                    <NumberField source="power" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export const ScheduleEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="validation_status"/>
        </SimpleForm>
    </Edit>
);

export const ScheduleCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <DateTimeInput source="date" validate={required()}/>
            <TextInput source="booking_id" validate={required()}/>
            <TextInput source="mfa_id" validate={required()}/>
            <ArrayInput source="ptus" validate={required()}>
                <SimpleFormIterator>
                    <DateTimeInput source="date" validate={required()}/>
                    <NumberInput source="power" validate={required()}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);