import { TextInput } from 'react-admin';

export const idFilter = [
    <TextInput label="Id" source="id" alwaysOn/>,
];

export const idNameFilter = [
    <TextInput label="Id" source="id" alwaysOn/>,
    <TextInput label="Name" source="name" alwaysOn/>,
];
