import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TextField, List, DateField, ArrayField, ChipField, Show, TabbedShowLayout, NumberField, Datagrid, ReferenceField, NumberInput,
   TextInput, required, SimpleForm, ArrayInput, SimpleFormIterator, Create, DateTimeInput, Tab } from "react-admin";
import { idFilter } from "../util/filters";

export const RemOptList = props => (
    <List filters={idFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <ReferenceField source="booking_id" reference="bookings">
                <TextField source="id" />
            </ReferenceField>
            <DateField source="pipeline.accepted" showTime={true}/>
            <TextField source="pipeline.status"/>
            <DateField source="task.submitted" showTime={true}/>
            <TextField source="task.freetext_reference" />
            <ReferenceField source="task.customer_id" reference="users">
                <TextField source="id" />
            </ReferenceField>
        </CustomizableDatagrid>
    </List>
);

export const RemOptShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Base">
                <TextField source="id" />
                <DateField source="modification_date" showTime={true}/>
                <ReferenceField source="booking_id" reference="bookings">
                    <TextField source="id" />
                </ReferenceField>
            </Tab>
            <Tab label="Task">
                <ChipField source="task._cls"/>
                <DateField source="task.submitted" showTime={true}/>
                <TextField source="task.freetext_reference" />
                <ReferenceField source="task.customer_id" reference="users">
                    <TextField source="id" />
                </ReferenceField>
                <ArrayField source="task.problem">
                    <Datagrid>
                        <DateField source="date" showTime={true} />
                        <NumberField source="power" />
                        <NumberField source="price" />
                    </Datagrid>
                </ArrayField>
                <TextField source="task.mfa_ids"/>
            </Tab>
            <Tab label="Autselect Properties">
                <NumberField source="task.autoselect_properties.max_price_kwh" />
                <NumberField source="task.autoselect_properties.energy_min_kwh" />
                <NumberField source="task.autoselect_properties.energy_max_kwh" />
                <NumberField source="task.autoselect_properties.power_min_kw" />
                <NumberField source="task.autoselect_properties.power_max_kw" />
                <NumberField source="task.autoselect_properties.gps_distance.lat" />
                <NumberField source="task.autoselect_properties.gps_distance.lon" />
                <NumberField source="task.autoselect_properties.gps_distance.distance_m" />
                <ArrayField source="task.autoselect_properties.address_locations">
                    <Datagrid>
                        <TextField source="city" />
                        <TextField source="street" />
                    </Datagrid>
                </ArrayField>
                <TextField source="task.autoselect_properties.excluded_mfa_ids" />
            </Tab>
            <Tab label="Pipeline">
                <DateField source="pipeline.accepted" showTime={true}/>
                <TextField source="pipeline.status"/>
                <NumberField source="pipeline.status_code" />
                <TextField source="pipeline.rem_id" />
            </Tab>
            <Tab label="Result">
                <DateField source="result.completion" showTime={true}/>
                <NumberField source="result.solution.total_price" />
                <NumberField source="result.solution.total_energy_error" />
                <NumberField source="result.solution.total_energy_demand" />
                <NumberField source="result.solution.total_energy_provided" />
                <ArrayField source="result.solution.mfas">
                    <Datagrid>
                        <TextField source="mfa_id" />
                        <DateField source="stime" showTime={true} />
                        <NumberField source="power" />
                        <NumberField source="energy" />
                        <NumberField source="price" />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const RemOptCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ArrayInput source="problem" validate={required()}>
                <SimpleFormIterator>
                    <DateTimeInput source="date" validate={required()}/>
                    <NumberInput source="power" validate={required()}/>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="mfa_ids">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="freetext_reference"/>
        </SimpleForm>
    </Create>
);