import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { Edit, List, TextField, DateField, ReferenceField, TextInput, required, SimpleForm, Create, Show, SimpleShowLayout, NumberInput, NumberField, DateTimeInput } from 'react-admin';
import { idFilter } from "../util/filters";

export const DatenspendeList = props => (
    <List filters={idFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <NumberField source="battery_cap_kwh" />
            <NumberField source="max_amp_kw" />
            <NumberField source="loading_state_start" />
            <NumberField source="loading_state_end" />
            <DateField source="loading_start" showTime={true}/>
            <DateField source="loading_stop" showTime={true}/>
            <ReferenceField source="dersystem_id" reference="dersystems">
                <TextField source="id"/>
            </ReferenceField>
            <ReferenceField source="mfa_id" reference="mfas">
                <TextField source="id"/>
            </ReferenceField>
        </CustomizableDatagrid>
    </List>
);

export const DatenspendeShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <NumberField source="battery_cap_kwh" />
            <NumberField source="max_amp_kw" />
            <NumberField source="loading_state_start" />
            <NumberField source="loading_state_end" />
            <DateField source="loading_start" showTime={true}/>
            <DateField source="loading_stop" showTime={true}/>
            <ReferenceField source="dersystem_id" reference="dersystems">
                <TextField source="id"/>
            </ReferenceField>
            <ReferenceField source="mfa_id" reference="mfas">
                <TextField source="id"/>
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);

export const DatenspendeCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <NumberInput source="battery_cap_kwh" validate={required()} />
            <NumberInput source="max_amp_kw" validate={required()} />
            <NumberInput source="loading_state_start" validate={required()} />
            <NumberInput source="loading_state_end" validate={required()} />
            <DateTimeInput source="loading_start" showTime={true} validate={required()}/>
            <DateTimeInput source="loading_stop" showTime={true} validate={required()}/>
            <TextInput source="dersystem_id" validate={required()} />
        </SimpleForm>
    </Create>
);

export const DatenspendeEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <NumberInput disabled source="battery_cap_kwh" />
            <NumberInput disabled source="max_amp_kw" />
            <NumberInput disabled source="loading_state_start" />
            <NumberInput disabled source="loading_state_end" />
            <DateTimeInput disabled source="loading_start" showTime={true}/>
            <DateTimeInput disabled source="loading_stop" showTime={true}/>
            <TextInput disabled source="dersystem_id" />
        </SimpleForm>
    </Edit>
);