import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TextField, List, DateField, ArrayField, Tab, Show, NumberField, Datagrid, ReferenceField, TabbedShowLayout, NumberInput,
   TextInput, required, Edit, SimpleForm, ArrayInput, SimpleFormIterator, Create } from "react-admin";
import { idFilter } from "../util/filters";

export const ScheduleTemplateList = props => (
    <List filters={idFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <ReferenceField source="user_id" reference="users">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="name" />
        </CustomizableDatagrid>
    </List>
);

export const ScheduleTemplateShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Base">
                <TextField source="id" />
                <DateField source="modification_date" showTime={true}/>
                <ReferenceField source="user_id" reference="users">
                    <TextField source="id" />
                </ReferenceField>
                <TextField source="name" />
            </Tab>
            <Tab label="Autselect Properties">
                <NumberField source="autoselect_properties.max_price_kwh" />
                <NumberField source="autoselect_properties.energy_min_kwh" />
                <NumberField source="autoselect_properties.energy_max_kwh" />
                <NumberField source="autoselect_properties.power_min_kw" />
                <NumberField source="autoselect_properties.power_max_kw" />
                <NumberField source="autoselect_properties.gps_distance.lat" />
                <NumberField source="autoselect_properties.gps_distance.lon" />
                <NumberField source="autoselect_properties.gps_distance.distance_m" />
                <ArrayField source="autoselect_properties.address_locations">
                    <Datagrid>
                        <TextField source="city" />
                        <TextField source="street" />
                    </Datagrid>
                </ArrayField>
                <TextField source="autoselect_properties.excluded_mfa_ids" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const ScheduleTemplateCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <NumberInput source="autoselect_properties.max_price_kwh" />
            <NumberInput source="autoselect_properties.energy_min_kwh" />
            <NumberInput source="autoselect_properties.energy_max_kwh" />
            <NumberInput source="autoselect_properties.power_min_kw" />
            <NumberInput source="autoselect_properties.power_max_kw" />
            <NumberInput source="autoselect_properties.gps_distance.lat" />
            <NumberInput source="autoselect_properties.gps_distance.lon" />
            <NumberInput source="autoselect_properties.gps_distance.distance_m" />
            <ArrayInput source="autoselect_properties.address_locations">
                <SimpleFormIterator>
                    <TextInput source="city"/>
                    <TextInput source="street"/>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="problem" validate={required()}>
                <SimpleFormIterator>
                    <TextInput source="time" validate={required()}/>
                    <NumberInput source="power" validate={required()}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export const ScheduleTemplateEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="name"/>
            <NumberInput source="autoselect_properties.max_price_kwh" />
            <NumberInput source="autoselect_properties.energy_min_kwh" />
            <NumberInput source="autoselect_properties.energy_max_kwh" />
            <NumberInput source="autoselect_properties.power_min_kw" />
            <NumberInput source="autoselect_properties.power_max_kw" />
            <NumberInput source="autoselect_properties.gps_distance.lat" />
            <NumberInput source="autoselect_properties.gps_distance.lon" />
            <NumberInput source="autoselect_properties.gps_distance.distance_m" />
            <ArrayInput source="autoselect_properties.address_locations">
                <SimpleFormIterator>
                    <TextInput source="city"/>
                    <TextInput source="street"/>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="problem" validate={required()}>
                <SimpleFormIterator>
                    <TextInput source="time" validate={required()}/>
                    <NumberInput source="power" validate={required()}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);