import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TextField, List, DateField, ArrayField, Show, NumberField, Datagrid, ReferenceField, TextInput, Edit, SimpleForm, Create, ChipField, SimpleShowLayout,
     DateInput, DateTimeInput } from "react-admin";

export const idRefFilter = [
    <TextInput label="Id" source="id" alwaysOn/>,
    <TextInput label="Reference" source="freetext_reference" alwaysOn/>,
];

export const ExecutionTaskList = props => (
    <List filters={idRefFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <ChipField source="_cls" />
            <TextField source="freetext_reference" />
            <ReferenceField source="template_id" reference="scheduletemplates">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField source="user_id" reference="users">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="state" />
            <DateField source="schedule_date" />
            <DateField source="execution_time" showTime={true}/>
            <ChipField source="post_action._cls" />
        </CustomizableDatagrid>
    </List>
);

export const ExecutionTaskShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <ChipField source="_cls" />
            <TextField source="freetext_reference" />
            <ReferenceField source="template_id" reference="scheduletemplates">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField source="user_id" reference="users">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="state" />
            <DateField source="schedule_date" />
            <DateField source="execution_time" showTime={true}/>
            <ChipField source="post_action._cls" />
            <ArrayField source="problem">
                <Datagrid>
                    <TextField source="time"/>
                    <NumberField source="power" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export const ExecutionTaskeCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="template_id"/>
            <DateInput source="schedule_date" />
            <DateTimeInput source="execution_time" />
        </SimpleForm>
    </Create>
);

export const ExecutionTaskEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="freetext_reference"/>
        </SimpleForm>
    </Edit>
);