import * as React from "react";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TextField, List, DateField, ArrayField, ChipField, Show, TabbedShowLayout, NumberField, Datagrid, ReferenceField, Tab } from "react-admin";
import { idFilter } from "../util/filters";

export const BookingList = props => (
    <List filters={idFilter} {...props} bulkActionButtons={false}>
        <CustomizableDatagrid rowClick="show">
            <TextField source="id" />
            <DateField source="modification_date" showTime={true}/>
            <DateField source="request.request_date" showTime={true}/>
            <ChipField source="state" />
            <ReferenceField source="broker_id" reference="users">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField source="customer_id" reference="businessentities">
                <TextField source="id" />
            </ReferenceField>
            <NumberField source="fee" />
            <TextField source="freetext_reference" />
        </CustomizableDatagrid>
    </List>
);

export const BookingShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Base">
                <TextField source="id" />
                <DateField source="modification_date" showTime={true}/>
                <ReferenceField source="broker_id" reference="users">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField source="customer_id" reference="businessentities">
                    <TextField source="id" />
                </ReferenceField>
                <NumberField source="fee" />
                <TextField source="freetext_reference" />
                <TextField source="mfas" />
            </Tab>
            <Tab label="Request">
                <DateField source="request.request_date" showTime={true}/>
                <TextField source="request.requested_mfa_ids"/>
            </Tab>
            <Tab label="Result">
                <DateField source="result.booking_date" showTime={true}/>
                <TextField source="result.booked_mfa_ids"/>
            </Tab>
            <Tab label="State">
                <TextField source="state" />
                <ArrayField source="state_history">
                    <Datagrid>
                        <ChipField source="state" />
                        <DateField source="timestamp" showTime={true} />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
